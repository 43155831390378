
import React from 'react';
import {  Button, Container, Navbar, Image, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Link } from 'react-router-dom';
import {Auth} from "aws-amplify"; 

import icon from '../assets/black-field-raven-icon.png'
import WeatherflowAuth from '../components/WeatherflowAuth';
import styled from 'styled-components';


const Styles = styled.div`
  .navbar {
    background-color: #5271ff;
  }
.btn-outline-danger {
  color: white;
  border-color: white;
  }
  #bg-body-tertiary {
    background-color: red;
  }
  a,
  .navbar-brand,
  .navbar-nav .nav-link {
    color: #ffffff;

    &:hover {
      color: #bbb;
    }
  }
`;

const NavigationBar = () => {
    return (
        <Styles>
 <Navbar expand='lg'>
      <Container>
        <Navbar.Brand href='/'>
            <Image src={icon} style={{width: '60px'}}/>
            &nbsp;FieldRaven
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto'>
            <Nav.Item>
              <Nav.Link>
                <Link to='/'>Home</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to='/about'>About</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to='/setup'>Setup</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link>
                <Link to='/Product'>Product</Link>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="justify-content-end">
              <Nav.Link>
                <Link to='/contact'>Contact</Link>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <WeatherflowAuth />
          <Button variant="outline-danger" onClick={() => Auth.signOut()}>Sign Out</Button>
        </Navbar.Collapse>      
      </Container>
    </Navbar>       
             
        </Styles> 
    )
  }
  
  export default NavigationBar;
