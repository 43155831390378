import './App.css';
import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import {Outlet, Routes,  Route} from 'react-router-dom';

import "@aws-amplify/ui-react/styles.css";

import PDF from './assets/GettingStarted.pdf';

import MainApp from './MainApp';

import LandingPage from './components/LandingPage';

import NavigationBar from './components/NavigationBar';

function Test() {
  return (
    <>
    <NavigationBar />
    <div>
      <h1>Test</h1>
    </div>
    </>
  );
}

function InstructionTab() { 
  return (
    <>
    <NavigationBar />
   <div style={{ width: '100%', height: '500px', border: '1px solid #ccc' }}>
        <iframe src={PDF} alt="Banner" style={{ width: '100%', height: '1000px', objectFit: 'cover' }} />
    </div>
    </>


  );
}

function Layout() {
  return (
    <div>
      {/* An <Outlet> renders whatever child route is currently active,
          so you can think about this <Outlet> as a placeholder for
          the child routes we defined above. */}
      <Outlet />
    </div>
  );
}

function App() {
  return (
    <>

    {/* Routes nest inside one another. Nested route paths build upon
          parent route paths, and nested route elements render inside
          parent route elements. See the note about <Outlet> below. */}
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<LandingPage />} />
        <Route path="login" element={<MainApp />} />
        <Route path="test" element={<Test />} />
        <Route path="setup" element={<InstructionTab />} />

        {/* Using path="*"" means "match anything", so this route
              acts like a catch-all for URLs that we don't have explicit
              routes for. */}
        <Route path="*" element={<Test />} />
      </Route>
    </Routes>
    </>
);
};

// TODO go into backend and make sure it only returns "Not Active" and never "Not active"

export default App;
