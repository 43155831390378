import React, { useState, useEffect } from 'react';
import { Spinner, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API } from 'aws-amplify';
import PDF from '../assets/GettingStarted.pdf';
import Banner from '../assets/Banner.png'
import WeatherflowCard from './WeatherflowCard';




// component to display data from latest report using API
const LatestReport = () => {
    const [report, setReport] = useState(null);
    const [loading, setLoading] = useState(true);
  
    // Function to get the latest report from the API
    const getLatestReport = async () => {
      try {
        const response = await API.get('weatherflowAPI', '/getWeatherflowReport');
        const data = JSON.parse(response);
        console.log('Weatherflow getLatestReport response:', data);
  
        setReport(data);
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log('Weatherflow getLatestReport error:', error);
      }
    };
  
    // Get the latest report on component mount
    useEffect(() => {
      getLatestReport();
    }, []);
  
    // If the report is still loading, display a loading indicator
    if (loading) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{minHeight: '300px'}}>
        <Spinner animation="border" role="status" />
      </div>
      );
    }
    if (report === null) {
      return ( 
        <Container className="mt-5"  >
        <h1>Email Services</h1>
        <p>
          Sign in to WeatherFlow to connect your Tempest station to FieldRaven for email updates on your weather data.
          <br />
          The first report will be sent within 24 hours of connecting your station. The report will also be displayed on this webpage.
        </p> 
        <div style={{ width: '100%', height: '500px', border: '1px solid #ccc' }}>
        <iframe src={PDF} alt="Banner" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
    </div>
        </Container>
    
      );
    }
  
    // If the report is finished loading, display the report
    return (
        <Container className="outside-container" style={{marginTop: '50px'}} >
      
      <Container className="weather-container" style={{overflowX: 'auto', display: 'inline-flex'}}>
        {report.daily.map((card) => (
          
          <WeatherflowCard report={card} />
        ))}
  
      </Container>
      </Container>
    );
  };

export default LatestReport;
