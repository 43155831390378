// LandingPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Image, Button, Spinner, Row, Col, ButtonGroup } from 'react-bootstrap';
import blackWhiteLogo from '../assets/black-field-raven-icon.png';
import blackIcon from '../assets/full-black-icon.png';
import whiteIcon from '../assets/white-field-raven-icon.png'
import blueIcon from '../assets/blue-icon.png'
import tempest from '../assets/tempest.png'
import dashboard from '../assets/dashboard.png'
//style a basic page to display on the landing page
// it should really just give a short description of the app and a button to login
import emailScreenshot from '../assets/new-email-screenshot-2.png'
import large_icon from '../assets/Field_Raven.png'
import banner from '../assets/Banner.png'
import squareBanner from '../assets/Field_Raven.png'
import leftHalfBanner from '../assets/left-half-banner.png'
import { useEffect, useState } from 'react';
import { Auth, API } from 'aws-amplify';
import {useNavigate} from 'react-router-dom'

import styled from 'styled-components';


const Styles = styled.div`
  
.flex-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1250px;
}

.flex-item {
  flex: 0 0 50%;
  align-items: center;
  justify-content: center;
}
.flex-banner {
  width: 100%;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
}

.vertical-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.navbar {
    background-color: grey;
}
.btn-outline-danger {
  color: white;
  border-color: white;
  }
  #bg-body-tertiary {
    background-color: red;
  }
  a,
  .navbar-brand,
  .navbar-nav .nav-link {
    color: #ffffff;

    &:hover {
      color: #bbb;
    }
  }
`;



const LandingPage = () => {
    
    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    
    // Function to get the current user's username
    const getCurrentUsername = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        return currentUser.username;
      } catch (error) {
        console.log('Error getting current user:', error);
        return null;
      }
    };
 
    const hasWeatherflowToken = async () => {
      try {
        const response = await API.get('weatherflowAPI', '/hasWeatherflowCredentials');
        console.log('Weatherflow hasToken response:', response);
        return response === 'True';
      } catch (error) {
        console.log('Weatherflow hasToken error:', error);
        return false;
      }
    };
  
    // Check if the user is connected on component mount
    useEffect(() => {
      const checkConnection = async () => {
        const isConnected = await hasWeatherflowToken();
      console.log('Weatherflow isConnected:', isConnected);
        setConnected(isConnected);
        setLoading(false);
      };
  
      checkConnection();
    }, []);
   
    // Handle the callback URL after Weatherflow authentication
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      console.log('Weatherflow auth code:', code);
  
      if (code) {
        // Get the current user's username
        getCurrentUsername().then((username) => {
          if (username) {
            alert('WeatherFlow Successfully Connected'); 
            // Use the username in the API call
            API.post('weatherflowAPI', '/setTokenFromAuth', {
              body: {
                code: code,
                username: username, // Add the username to the body
              },
            })
              .then((response) => {
                console.log('Weatherflow auth response:', response);
                // Redirect to the home page after successful authentication
                // window.location.href = "/callback";
              })
              .catch((error) => {
                console.log('Weatherflow auth error:', error);
              });
          }
        });
      }
    }, []);


    // After the loading check, use connected to trigger a redirect
    useEffect(() => {
        if (!loading && connected) {
            navigate('/login'); // Redirect to '/login' when connected is true
        }
    }, [connected, loading, navigate]); // Ensure useEffect dependencies

    if (loading) {
      return( 
      <div className="d-flex justify-content-center align-items-center" style={{minHeight: '300px'}}>
        <Spinner animation="border" role="status" />
      </div>
      );
    }
  const externalUrl = 'https://shop.weatherflow.com/pages/scotsmanturfrobotics-tempestone';
  return (
    <Styles>
      <Navbar expand='lg'>

      <Container>
        <Navbar.Brand href="#home" style={{fontSize: '2rem'}}>
            <Image src={whiteIcon} style={{width: '60px'}}/>

            &nbsp;Field Raven
          </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse className='justify-content-end'>
  
          <Navbar.Text> 
          {/* button link to website to buy the device */}
            <ButtonGroup aria-label="Basic example">
              <Button variant="secondary" size='lg' as={Link} to={externalUrl}>
               Purchase Tempest Weather Station
              </Button>
              <Button variant="primary" size="lg" as={Link} to="/login">
                Sign Up/Sign In
              </Button>
            </ButtonGroup>

          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>

   
    <Container className='flex-container' fluid>
    <Row style={{ width: '105%'}}>
      <Col sm={6} md={6} style={{background: '#5271ff'}}>
        <div style={{background: '#5271ff'}}>
          <Image src={leftHalfBanner}  fluid/>
        </div>
      </Col>

      <Col sm={6} md={6}style={{background: '#ededed'}}>
        <div style={{padding: '100px'}} >
          <h1>Welcome to the Field Raven </h1> 
          <p>Weather and Disease Management Tool for Turfgrass</p>
        </div>
      </Col>
    </Row>
    
    <Row style={{width: '105%'}}>
    <Col sm={6} md={6} style={{background: '#ededed'}}>
        <div style={{ padding: '100px'}}>
          <h1>Tempest Weather System</h1>
          <p>Field Raven uses the Tempest Weather Station to provide real time weather data to aid turfgrass management and forecast disease threats.</p>
        </div>
    </Col>   
    <Col sm={6} md={6} style={{background: 'white'}}>
    <Container className='vertical-center' style={{background: 'white'}}>
       <Image src={tempest} fluid/>
      </Container>
    </Col>

    </Row>
    
    <Row style={{width: '105%'}}>
    <Col sm={6} md={6} style={{background: 'white'}}>
      <Container style={{background: 'white'}}>
       <Image src={emailScreenshot} fluid/>
      </Container>
    </Col>

    <Col sm={6} md={6} style={{background: '#ededed'}}>
      <div className='vertical-center' style={{ padding: '100px'}}>
      <div style={{ paddingBottom: '60px'}}>
        <h1>Sign Up</h1>
          Sign up here to create an account and link your Tempest Weather Station to Field Raven
      </div>
      <Button variant="primary" size="lg" as={Link} to="/login">Sign Up</Button>
      </div>
    </Col>
    </Row>
    
    <Row style={{width: '105%', marginTop: '40px', marginBottom: '20px'}}>
    <Col sm={12} md={12} style={{background: '#5271ff'}}>
      <div className='vertical-center' style={{ padding: '60px', color: 'white', background: '#5271ff'}}>
        <h1>What do you get with Field Raven?</h1>
      </div>
    </Col>
    </Row>
    
    <Row style={{width: '105%'}}>
    <Col sm={6} md={6} style={{background: '#ededed'}}>
   <div className='flex-item vertical-center' style={{background: '#ededed'}}>
        <div style={{ padding: '100px'}}>
          <h1> The Dashboard </h1>
          <p> The dashboard is your all in one weather and turfgrass management tool. </p>
        </div>
    </div>   

    </Col>


    <Col sm={6} md={6} style={{background: 'white'}}>
    <div className='vertical-center'>
       <Image src={dashboard} style={{width: "100%"}} fluid/>
    </div>
    </Col>
    </Row>


    <Row style={{width: '105%'}}>

    <Col sm={6} md={6} style={{background: 'white'}}>
    <div className='vertical-center'>
       <Image src={emailScreenshot} style={{width: "100%"}} fluid/>
    </div>
    </Col>
    <Col sm={6} md={6} style={{background: '#ededed'}}>
        <div style={{ padding: '100px'}}>
          <h1> Email Alerts</h1>
          <p> Email alerts are sent to you every day to give you a snapshot of possible threats before the occur. </p>
        </div>
    </Col>
    </Row>

    </Container>
   </Styles>
    )
}

export default LandingPage;
