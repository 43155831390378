import React from 'react';
import {  Button, Container, Navbar, Image, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Link } from 'react-router-dom';

import icon from './assets/black-field-raven-icon.png'
import LatestReport from './components/LatestReport';
import WeatherflowAuth from './components/WeatherflowAuth';
import NavigationBar from './components/NavigationBar';
import { Auth } from 'aws-amplify';

import "@aws-amplify/ui-react/styles.css";
//import withAuthenticator from "@aws-amplify/ui-react";
import { withAuthenticator } from '@aws-amplify/ui-react';

const MainApp = () => {
    return (
    <>
            <NavigationBar /> 
            <LatestReport />

        
    </>
    )
  }
  
  export default withAuthenticator(MainApp);
