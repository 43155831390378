import React from "react";
import {  Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

import mostlyCloudySVG from '../assets/mostly-cloudy-outline.svg';
import thunderstormSVG from '../assets/thunderstorms.svg';
import sunnySVG from '../assets/sunny.svg';
import rainSVG from '../assets/cloud-rain.svg';
import snowSVG from '../assets/cloud-snow.svg';
import sleetSVG from '../assets/cloud-sleet.svg';
import drizzleSVG from '../assets/cloud-drizzle.svg';
import windSVG from '../assets/wind.svg';
import fogSVG from '../assets/cloud-fog2.svg';


// component to display data from latest report using API
const WeatherflowCard = (props) => {
    const {report} = props;
    console.log('WeatherflowCard props:', props);

    const month_dict = {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "June",
        7: "July",
        8: "Aug",
        9: "Sept",
        10: "Oct",
        11: "Nov",
        12: "Dec"
    };

    // Not Active, Low,  Mild, Moderate, High

    const disease_color = {
        "Not active": "success",
        "Not Active": "success",
        "Low": "success",
        "Mild": "warning",
        "Moderate": "warning",
        "High": "danger"
    };
//     // Clear
// Rain Likely
// Rain Possible
// Snow
// Snow Possible
// Wintry Mix Likely
// Wintry Mix Possible
// Thunderstorms Likely
// Thunderstorms Possible
// Windy
// Foggy
// Cloudy
// Partly Cloudy
// Very Light Rain

    const precip_icon = {
        "Thunderstorms Likely": thunderstormSVG,
        "Thunderstorms Possible": thunderstormSVG,
        "Partly Cloudy": mostlyCloudySVG,
        "Mostly Cloudy": mostlyCloudySVG,
        "Sunny": sunnySVG,
        "Clear": sunnySVG,
        "Rain Likely": rainSVG,
        "Rain Possible": rainSVG,
        "Snow": snowSVG,
        "Snow Possible": snowSVG,
        "Wintry Mix Likely": sleetSVG,
        "Wintry Mix Possible": sleetSVG,
        "Very Light Rain": drizzleSVG,
        "Windy": windSVG,
        "Foggy": fogSVG,
    };

    // my report example: {month_num, day_num, conditions, air_temp_high, air_temp_low, precip_probability, precip_type, relative_humidity, miniRing, pythiumBlight, dollarSpot, largePatch, brownPatch, grayLeafSpot, anthracnose}


    const getDayOfWeek = (monthNum, dayNum) => {
        // Create a new Date object with the current year (or specify the year you want to use)
        const currentDate = new Date();
      
        // Set the month and day of the Date object
        currentDate.setMonth(monthNum - 1); // Month numbers are 0-based, so subtract 1
        currentDate.setDate(dayNum);
      
        // Get the day of the week using toLocaleDateString
        const dayOfWeek = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
        return dayOfWeek;
      };


    return (
    <Card style={{ width: '320px', flex: '0 0 auto', marginRight: '30px', marginBottom: "30px"}}    >
        <Card.Header style={{}}>
            <strong>{getDayOfWeek(report.month_num, report.day_num)},</strong>&nbsp;&nbsp;{ month_dict[report.month_num] + " " + report.day_num}  
        </Card.Header>
        <Card.Img variant="top" className="mx-auto" style={{marginTop: "20px", width: "35%", marginBottom: '0px'}} src={precip_icon[report.conditions]} />

        <Card.Body >
            <Container style={{marginTop: '20px', color: "#636262", borderRadius: '10px', marginBottom: '20px', whiteSpace: 'nowrap'}} >
                <Row>
                    <Col> <strong>Conditions: </strong>{report.conditions}</Col>
                </ Row>
                <Row>
                    <Col > <strong>High: </strong>{report.air_temp_high}°C&nbsp;&nbsp;&nbsp;<strong>Low: </strong>{report.air_temp_low}°C</Col>
                </ Row>
                <Row>
                    <Col> <strong>Precip: </strong>{report.precip_probability}% chance of {report.precip_type} </Col>
                </ Row>
                <Row>
                    <Col> <strong>Humidity: </strong>{report.relative_humidity}%</Col>
                </ Row>
            </Container>
    

            <ListGroup variant="flush">
                <ListGroup.Item variant={disease_color[report.miniRing]} >Mini Ring: {report.miniRing}</ListGroup.Item>
                <ListGroup.Item variant={disease_color[report.pythiumBlight]} >Pythium Blight: {report.pythiumBlight}</ListGroup.Item>
                <ListGroup.Item variant={disease_color[report.dollarSpot]} >Dollar Spot: {report.dollarSpot}</ListGroup.Item>
                <ListGroup.Item variant={disease_color[report.largePatch]} >Large Patch: {report.largePatch}</ListGroup.Item>
                <ListGroup.Item variant={disease_color[report.brownPatch]} >Brown Patch: {report.brownPatch}</ListGroup.Item>
                <ListGroup.Item variant={disease_color[report.grayLeafSpot]} >Gray Leaf Spot: {report.grayLeafSpot}</ListGroup.Item>
                <ListGroup.Item variant={disease_color[report.anthracnose]} >Anthracnose: {report.anthracnose}</ListGroup.Item>


            </ListGroup>
        </Card.Body>
    </Card>
    );
};

export default WeatherflowCard;
