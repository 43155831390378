import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Auth, API } from 'aws-amplify';


// import svg 

const WeatherflowAuth = () => {

    // get the current user name from Auth
  
    const [connected, setConnected] = useState(false);
    const [loading, setLoading] = useState(true);
  
    // Function to get the current user's username
    const getCurrentUsername = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        return currentUser.username;
      } catch (error) {
        console.log('Error getting current user:', error);
        return null;
      }
    };
  
    const handleWeatherflowAuth = () => {
      // Replace these with your actual Weatherflow API credentials and redirect URL
      const clientId = '02a36230-d426-440a-8563-45bb2d80b9d6';
      const redirectUri = window.location.origin;
      console.log('Weatherflow auth redirectUri:', redirectUri);  
      // Redirect the user to the Weatherflow authentication page and then once it's done, redirect them back to the callback page
      //window.location.href = `https://tempestwx.com/authorize.html?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`;
      window.location.replace(`https://tempestwx.com/authorize.html?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}`);
    };
  
    const hasWeatherflowToken = async () => {
      try {
        const response = await API.get('weatherflowAPI', '/hasWeatherflowCredentials');
        console.log('Weatherflow hasToken response:', response);
        return response === 'True';
      } catch (error) {
        console.log('Weatherflow hasToken error:', error);
        return false;
      }
    };
  
    // Check if the user is connected on component mount
    useEffect(() => {
      const checkConnection = async () => {
        const isConnected = await hasWeatherflowToken();
        setConnected(isConnected);
        setLoading(false);
      };
  
      checkConnection();
    }, []);
  
    // Handle the callback URL after Weatherflow authentication
    useEffect(() => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');
      console.log('Weatherflow auth code:', code);
  
      if (code) {
        // Get the current user's username
        getCurrentUsername().then((username) => {
          if (username) {
  
            // Use the username in the API call
            API.post('weatherflowAPI', '/setTokenFromAuth', {
              body: {
                code: code,
                username: username, // Add the username to the body
              },
            })
              .then((response) => {
                console.log('Spotify auth response:', response);
                // Redirect to the home page after successful authentication
                // window.location.href = "/callback";
              })
              .catch((error) => {
                console.log('Spotify auth error:', error);
              });
          }
        });
      }
    }, []);

    return (
      loading ? (
        <Spinner animation="border" role="status" style={{marginRight: '20px', marginBottom: '0px' }}/>
        ) : (
        !connected ? (

 
            <Button variant="primary" size="large" onClick={handleWeatherflowAuth}>Connect Weatherflow</Button>

        ) : (
            <Button variant="success" size="large" disabled>Connected</Button>)
        )
    );
    
  };
  
export default WeatherflowAuth;
